<template>
  <div class="MenuRoleDetails">
    <PocCardTable>
      <template v-slot:headerTitle>权限详情</template>
      <template v-slot:headerGroup>
        <el-button @click="Back">返回</el-button>
        <el-button type="primary" @click="goEdit" role="edit" v-permission
          >编辑</el-button
        >
      </template>
      <template v-slot:queryList>
        <el-descriptions :column="4">
          <el-descriptions-item label="权限编码">{{
            menuRolePageData.desObj.permissionCode
          }}</el-descriptions-item>
          <el-descriptions-item label="权限名称">{{
            menuRolePageData.desObj.permissionName
          }}</el-descriptions-item>
          <!--          <el-descriptions-item label="所属机构"-->
          <!--            >{{ orgName }} -->
          <!--            <el-tooltip placement="top">-->
          <!--              <div slot="content">-->
          <!--                <div v-for="(item, index) in orgNames" :key="index">-->
          <!--                  {{ item.name }}-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <svg-icon class="detailsIcon" icon="details" />-->
          <!--            </el-tooltip>-->
          <!--          </el-descriptions-item>-->

          <!--            <el-popover placement="right" width="400" trigger="hover">-->
          <!--              <el-table :data="orgNames">-->
          <!--                <el-table-column prop="name" />-->
          <!--              </el-table>-->
          <!--            </el-popover>-->
        </el-descriptions>
      </template>
      <div class="headerAllSelect">
        <div class="leftTitle">已分配权限({{ allocated }})</div>
      </div>
      <PocTable
        v-loading="menuRolePageData.loading"
        :data="menuRolePageData.tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="menuRolePageData.total"
        stripe
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        ></el-table-column>
      </PocTable>
    </PocCardTable>
  </div>
</template>

<script>
import { detailsColumnConfig } from '@/views/menuRoleManage/TableColumnConfig'
import { sysPermissionsGetData } from '@/views/menuRoleManage/Hooks/sysPermissionsGetData'
import { check, typeRange } from '@/utils/util'

export default {
  components: {},

  mixins: [$PCommon.TableMixin],
  name: 'MenuRoleDetails',
  data() {
    return {
      columnConfig: detailsColumnConfig,
      menuRolePageData: {
        loading: false,
        desObj: {
          permissionCode: '',
          permissionName: '',
          orgNames: []
        },
        tableData: [],
        total: 0,
        id: ''
      }
    }
  },
  computed: {
    orgNames() {
      if (
        check(this.menuRolePageData.desObj.orgNames) === typeRange.array &&
        this.menuRolePageData.desObj.orgNames.length
      ) {
        return this.menuRolePageData.desObj.orgNames.map((item) => {
          return {
            name: item.join(' - ')
          }
        })
      }
      return []
    },
    orgName() {
      if (
        check(this.menuRolePageData.desObj.orgNames) === typeRange.array &&
        this.menuRolePageData.desObj.orgNames.length
      ) {
        return this.menuRolePageData.desObj.orgNames[0].join('-')
      }
      return ''
    },
    allocated() {
      return this.menuRolePageData.tableData.length
    }
  },
  created() {
    this.menuRolePageData.id = this.$route.query.id
    if (this.menuRolePageData.id) {
      this.getData()
    } else {
      this.Back()
      return new Error('id未找到，请检查url，或表格数据')
    }
  },
  methods: {
    queryApi() {
      this.getData()
    },
    getData() {
      const obj = {
        permissionsId: this.menuRolePageData.id,
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum
      }
      sysPermissionsGetData(obj, this.menuRolePageData, 'details')
    },
    goEdit() {
      if (this.menuRolePageData.id) {
        const id = this.menuRolePageData.id
        this.$router.push({
          path: '/system/permissionManage/edit',
          query: {
            id,
            status: 'edit'
          }
        })
      } else {
        return new Error('当前点击无返回，检查接口数据')
      }
    },
    Back() {
      this.$router.push({
        path: '/system/permissionManage'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.MenuRoleDetails {
  :deep(.el-card) {
    .el-card__header {
      padding: 20px;
      border-bottom: 15px solid rgb(242, 242, 242);
      .el-descriptions-item__container {
        .el-descriptions-item__label {
          font-size: 14px;
          font-family: ArialMT;
          color: #868686;
        }
        .el-descriptions-item__content {
          font-size: 14px;
          font-family: ArialMT;
          color: #323435;
        }
      }
    }
    .el-card__body {
      padding: 20px;
      .headerAllSelect {
        .leftTitle {
          font-size: 14px;
          font-weight: bold;
          color: #303133;
          margin-bottom: 23px;
        }
      }
    }
  }
}
.detailsIcon {
  position: absolute;
  left: 280px;
  top: 2px;
  font-size: 17px;
  margin-left: 8px;
}
</style>
