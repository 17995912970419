export const columnConfig = [
  {
    key: 'permissionCode',
    name: '权限编号',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: false // 默认宽度，像素
  },
  {
    key: 'permissionName',
    name: '权限名称',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  },
  {
    key: 'createTime',
    name: '创建信息',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  }
]

export const detailsColumnConfig = [
  {
    key: 'id',
    name: '权限编号',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: false // 默认宽度，像素
  },
  {
    key: 'menuName',
    name: '权限名称',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  },
  {
    key: 'createUser',
    name: '授权人',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  },
  {
    key: 'createTime',
    name: '授权时间',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: false
  }
]
